import React, { Component } from "react";
import { Button } from "react-bootstrap";

const EnviarComentButton = () => {
  return (
      <Button className="enviar-comment-button" type="submit">
        ENVIAR
      </Button>
  );
  //}
};

export default class EmpezarPlan extends Component {
  render() {
    return (
      <div className="contact-enviar-button">
        <EnviarComentButton />
      </div>
    );
  }
}
