import React, { Component, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import IniciaSesion from "../../../components/IniciaSesionButton";

const LoginForm = () => {
  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      termsAndConditions: false,
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .max(15, "Tiene que tener como maximo 15 caracteres")
        .required("Required"),
      email: Yup.string().email("Email Invalido").required("Email Required"),
      password: Yup.string()
        .min(8, "Tiene que tener como minimo 8 caracteres")
        .required("Password Required"),
      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required"),
      termsAndConditions: Yup.bool().oneOf(
        [true],
        "You need to accept the terms and conditions"
      ),
    }),
    onSubmit: async (values, actions) => {
      console.log(values);
      actions.resetForm();
    },
  });

  return (
    <div>
      <Row className="login-internel-right-row">
        <Col>
          <h1 className="titulo">Registrate</h1>
          <div className="login-field-form">
            <form onSubmit={formik.handleSubmit}>
              <div className="input-container">
                <input
                  id="username"
                  name="username"
                  type="username"
                  placeholder="Nombre de usuario"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  className={
                    formik.errors.username && formik.touched.username
                      ? "input-error"
                      : "input-email"
                  }
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className="error">{formik.errors.username}</div>
                ) : null}
              </div>
              <br />
              <div className="input-container">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={
                    formik.errors.email && formik.touched.email
                      ? "input-error"
                      : "input-email"
                  }
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="error">{formik.errors.email}</div>
                ) : null}
              </div>
              <br />
              <div className="input-container">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Contraseña"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={
                    formik.errors.password && formik.touched.password
                      ? "input-error"
                      : "input-email"
                  }
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="error">{formik.errors.password}</div>
                ) : null}
              </div>
              <br />
              <div className="input-container">
                <input
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  type="password"
                  placeholder="Repetir Contraseña"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  className={
                    formik.errors.passwordConfirmation &&
                    formik.touched.passwordConfirmation
                      ? "input-error"
                      : "input-email"
                  }
                />
                {formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation ? (
                  <div className="error">
                    {formik.errors.passwordConfirmation}
                  </div>
                ) : null}
              </div>
              <br />
              <div>
                <label>
                  <input
                    id="termsAndConditions"
                    name="termsAndConditions"
                    type="checkbox"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.termsAndConditions}
                    className="checkbox-register"
                  />
                  Acepto terminos y condiciones.
                  {formik.errors.termsAndConditions && (
                    <p>{formik.errors.termsAndConditions}</p>
                  )}
                </label>
              </div>
              <br />
              <IniciaSesion type="submit"></IniciaSesion>
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const LoginImage = () => {
  return <div></div>;
};

export default class homePage extends Component {
  render() {
    return (
      <div>
        <Container className="login-container">
          <Row className="left-login-row">
            <Col className="login-left-col">
              <LoginImage />
            </Col>
            <Col className="login-right-col">
              <LoginForm />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
