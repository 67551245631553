import React, { Component } from "react";
import { Button } from "react-bootstrap";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  NavbarText,
} from "reactstrap";
// import CreaUnMeraki from "../../../components/ButtonCreaUnMeraki";
import IniciaSesion from "../../../components/IniciaSesionButton";
import Registrarse from "../../../components/RegistrarseButton";
import logo from "../images/meraki.svg";

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.toggleNav = this.toggleNav.bind(this);
    this.state = {
      isNavOpen: false,
    };
  }

  toggleNav() {
    this.setState({
      isNavOpen: !this.state.isNavOpen,
    });
  }

  render() {
    return (
      <div>
        <Navbar container="md" expand="xl" fixed="top" fill className="navbar">
          <NavbarBrand href="/">
            <Button className="logo-button" variant="logo-button">
              <img className="logo" src={logo} alt="logo" />
            </Button>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNav} />
          <Collapse isOpen={this.state.isNavOpen} navbar>
            <Nav className="me-auto" navbar align-items-end>
              <NavItem>
                <NavLink href="/" className="central-buttons">
                  {" "}
                  SOBREMERAKI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/" className="central-buttons">
                  {" "}
                  WER UN MERAKI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/" className="central-buttons">
                  {" "}
                  PLANES
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/" className="central-buttons">
                  {" "}
                  CONTACTO
                </NavLink>
              </NavItem>
            </Nav>
            <NavLink className="idiomas"> EN/ES</NavLink>
            <NavLink href="/login" className="nav-login-button">
              <IniciaSesion />
            </NavLink>
            <NavbarText>
              <NavLink href="/register">
                <Registrarse />
              </NavLink>
            </NavbarText>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
