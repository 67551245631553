import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import Enviar from "../../../components/EnviarComment";

const ContactForm = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      email: "",
      comment: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name Required"),
      lastname: Yup.string().required("LastRequired"),
      email: Yup.string().email("Email Invalido").required("Email Required"),
      comment: Yup.string().required("Comment Required"),
    }),
    onSubmit: async (values, actions) => {
      console.log(values);
      actions.resetForm();
    },
  });

  return (
    <div className="contact-form-div">
      <Row className="contac-form-row">
        <Col>
          <div className="contac-form-row">
            <form onSubmit={formik.handleSubmit}>
              <div className="contact-input-container">
                <input
                  id="name"
                  name="name"
                  type="name"
                  placeholder="Nombre"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  className={
                    formik.errors.name && formik.touched.name
                      ? "contact-input-error"
                      : "contact-input-name"
                  }
                />
                <input
                  id="lastname"
                  name="lastname"
                  type="lastname"
                  placeholder="Apellido"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastname}
                  className={
                    formik.errors.lastname && formik.touched.lastname
                      ? "contact-input-error"
                      : "contact-input-lastname"
                  }
                />
              </div>
              <div className="contact-input-container">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={
                    formik.errors.email && formik.touched.email
                      ? "contact-input-error"
                      : "contact-input-email"
                  }
                />
              </div>
              <div className="contact-input-container">
                <textarea
                  id="comment"
                  name="comment"
                  type="comment"
                  placeholder="Escríbenos un mensaje."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.comment}
                  className={
                    formik.errors.comment && formik.touched.comment
                      ? "contact-input-error"
                      : "contact-input-comment"
                  }
                />
                <br />

                {(formik.touched.name && formik.errors.name) ||
                (formik.touched.lastname && formik.errors.lastname) ||
                (formik.touched.email && formik.errors.email) ||
                (formik.touched.comment && formik.errors.comment) ? (
                  <label className="contact-error">Check the fields.</label>
                ) : null}
              </div>
              <br />
                <Enviar type="submit"></Enviar> 
            </form>
          </div>
        </Col>
      </Row>
    </div>
    /*
    {formik.touched.lastname && formik.errors.lastname ? (
      <label className="error">{formik.errors.lastname}</label>
    ) : null}
    {formik.touched.email && formik.errors.email ? (
      <label className="error">{formik.errors.email}</label>
    ) : null}
    {formik.touched.comment && formik.errors.comment ? (
      <label className="error">{formik.errors.comment}</label>
    ) : null}
    */
  );
};

export default class Contact extends Component {
  render() {
    return (
      <div className="contact-div">
        <Container className="contact-container">
          <Row className="contact-row">
            <Col>
              <div className="contact-div">
                <h1 className="contact-titulo">
                  Contacto <br />
                </h1>
                <ContactForm />
              </div>
            </Col>
            <Col></Col>
          </Row>
        </Container>
      </div>
    );
  }
}
