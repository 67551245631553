import React, { Component } from "react";
import Header from "../features/navBar/presentation/HeaderComponent";
import Message from "../features/animatedMessage/presentation/MessageComponent";
import HomePage from "../features/homePage/presentation/HomePage";
import LoginPage from "../features/loginPage/presentation/LoginPage";
import RegisterPage from "../features/registerPage/presentation/RegisterPage";
import { Switch, Route, Redirect } from "react-router-dom";

class Main extends Component {
  render() {
    return (
      <div>
        <Header />
        <Message />
        <Switch>
          <Route path="/home" component={() => <HomePage />} />
          <Route path="/login" component={() => <LoginPage />} />
          <Route path="/register" component={() => <RegisterPage />} />
          <Redirect to="/home" />
        </Switch>
      </div>
    );
  }
}
//<Route exact path='/how' component={() => <HowWork />} />
export default Main;
