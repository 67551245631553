import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default class Message extends Component {
  render() {
    return (
      <div className="letrero">
        <Container className="letrero-container">
          <Row className="left-welcome-row">
            <Col className="welcome-left-col">
              <h3 className="titulo">
                # Mensajes que llegan justo a tiempo, recuerdos que duran para
                siempre #
                <br />
              </h3>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
