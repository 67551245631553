import "bootstrap/dist/css/bootstrap.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Nav, NavLink, Button } from "reactstrap";
import { Component } from "react";
import example from "../images/example.svg";
import arrow from "../images/arrow.svg";
import EmpezarUnMeraki from "../../../components/EmpezarUnMerakiFondoNegro";
import EmpezarPlan from "../../../components/EmpezarUnPlan";

export default class FuncionalityContainer extends Component {
  render() {
    return (
      <div className="funcionality-div">
        <Container className="funcionality-container">
        <Row className="funcionality-images">
            <Col>
              <div className="funcionality-div">
              <EmpezarPlan />
                <h1 className="funcionality-titulo">
                  Texto sobre la <br />
                  funcionalidad de <br />
                  la web
                </h1>
                <br />
                <h5 className="funcionality-comentario">
                  Otro texto explicativo
                </h5>
                <br />
              </div>
            </Col>
            <Col>
              <div className="funcionality-div">
              <EmpezarPlan />
                <h1 className="funcionality-titulo">
                  Texto sobre la <br />
                  funcionalidad de <br />
                  la web
                </h1>
                <br />
                <h5 className="funcionality-comentario">
                  Otro texto explicativo
                </h5>
                <br />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
