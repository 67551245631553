import "bootstrap/dist/css/bootstrap.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import happyFace from "../images/happy-face.svg";
import crown from "../images/crown.svg";
import star from "../images/star.svg";
import tick from "../images/tick.svg";
import EmpezarPlan from "../../../components/EmpezarUnPlan";
import { Component } from "react";

export default class Plan extends Component {
  render() {
    return (
      <div className="plan-div">
        <Container className="plan-container">
          <Row className="left-plan-row">
            <Col className="plan-left-col">
              <h4 className="titulo">
                Escoge el plan
                <br />
                se ajuste a tu evento
              </h4>
            </Col>
            <Col></Col>
          </Row>
          <Row className="plan-images">
            <Col className="plan-col">
              <div className="plan-div-col-happy">
                <img
                  src={happyFace}
                  className="happyFace-image"
                  alt="happyFace"
                />
                <h2>Gratuito</h2>
                <h5 className="plan-text">
                  Perfecto para crear un Meraki de
                  <br />
                  1 a 10 tarjetas entre tus amigos
                  <br />y familiares.
                </h5>
                <h5 className="plan-text">
                <img src={tick} className="tick-image" alt="tick" />
                De 1 a 10 tarjetas
                <br/>
                <img src={tick} className="tick-image" alt="tick" />
                Añade textos y fotografías
                <br/>
                <img src={tick} className="tick-image" alt="tick" />
                Cambia de plan en cualquier
                <br/> momento
                </h5>
                <EmpezarPlan />
              </div>
            </Col>
            <Col className="plan-col">
              <div className="plan-div-col-star">
                <img src={star} className="star-image" alt="star" />
                <h2>
                  Premium
                  <br />
                  5,99€
                </h2>
                <h5 className="plan-text">
                Tarjetas personalizadas para 
                  <br />
                  grupos grandes.
                </h5>
                <h5 className="plan-text">
                <img src={tick} className="tick-image" alt="tick" />
                Hasta 100 tarjetas
                <br/>
                <img src={tick} className="tick-image" alt="tick" />
                Añade vídeos y Gifs
                <br/>
                <img src={tick} className="tick-image" alt="tick" />
                Cambia de plan en cualquier
                <br/> momento
                </h5>
                <EmpezarPlan />
              </div>
            </Col>
            <Col className="plan-col">
              <div className="plan-div-col-crown">
                <img src={crown} className="crown-image" alt="crown" />
                <h2>
                  VIP
                  <br />
                  19,99€
                </h2>
                <h5 className="plan-text">
                Tarjetas ilimitadas para 
                  <br />
                  celebracions a gran escala.
                </h5>
                <h5 className="plan-text">
                <img src={tick} className="tick-image" alt="tick" />
                Tarjetas ilimitadas
                <br/>
                <img src={tick} className="tick-image" alt="tick" />
                Sube tu propio fondo
                <br/>
                <img src={tick} className="tick-image" alt="tick" />
                Cambia de plan en cualquier
                <br/> momento
                </h5>
                <EmpezarPlan />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
