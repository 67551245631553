import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import WelcomePage from "./WelcomeContainer";
import ExamplePage from "./ExampleContainer";
import HowWork from "./HowWorkContainer";
import Plan from "./PlanContainer";
import Funcionality from "./WebFuncionalityContainer";
import About from "./AboutContainer";
import Contact from "./ContactUsContainer";

export default class homePage extends Component {
  render() {
    return (
      <div>
        <WelcomePage/>
        <HowWork />
        <ExamplePage />
        <Plan />
        <Funcionality/>
        <Contact />
      </div>
    )
  }
}