import React, { Component } from "react";
import { Button } from "react-bootstrap";

const EmpezarPlanButton = () => {
  return (
    <div>
      <Button className="empezar-plan">
        EMPEZAR
      </Button>
    </div>
  );
  //}
};

export default class EmpezarPlan extends Component {
  render() {
    return (
      <div>
        <EmpezarPlanButton />
      </div>
    );
  }
}
