import "bootstrap/dist/css/bootstrap.css";
import logo1 from "../images/1.png";
import logo2 from "../images/2.png";
import logo3 from "../images/3.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Component } from "react";

export default class HowWork extends Component {
  render() {
    return (
      <div className="how-work-div">
        <Container className="how-work-container">
          <Row className="left-how-work-row">
            <Col className="how-work-left-col">
              <h4 className="titulo">¿Cómo funciona meraki?</h4>
              <br />
              <h5 className="comentario">
                Infinitas tarjetas con fotos y vídeos creadas de forma
                <br />
                compartida junto a tus amigos y familia.
              </h5>
            </Col>
            <Col></Col>
          </Row>
          <Row className="how-work-images">
            <Col className="number1-col">
              <img src={logo1} className="number1" alt="logo" />
              <h6>Crea un Meraki</h6>
              <h5 className="number-div">
                Crea un mensaje grupal para su <br />
                compañero y agrega mensajes, <br />
                GIFs o videos.
              </h5>
            </Col>
            <Col>
              <img src={logo2} className="number2" alt="logo" />
              <h6>Invita a sus amigos</h6>
              <h5 className="number-div">
                Invita a otros participantes para que
                <br />
                contribuyan con la terjeta y ver como
                <br />
                crece.
              </h5>
            </Col>
            <Col>
              <img src={logo3} className="number3" alt="logo" />
              <h6>Envia tu Meraki</h6>
              <h5 className="number-div">
                Crea una tarjeta grupal para su <br />
                compañero y agrega mensajes, fotos, <br />
                GIF o videos.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
