import React, { Component, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik, Formik } from "formik";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import IniciaSesion   from "../../../components/IniciaSesionButton";

const LoginForm = () => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Email Invalido").required("Email Required"),
      password: Yup.string()
        .min(8, "Tiene que tener como minimo 8 caracteres")
        .required("Password Required"),
    }),
    onSubmit: async (values, actions) => {
      console.log(values);
      actions.resetForm();
    },
  });

  return (
    <div>
      <Row className="login-internel-right-row">
        <Col>
          <h1 className="titulo">Inicia Sesion</h1>
          <div className="login-field-form">
          <form onSubmit={formik.handleSubmit}>
            <div className="input-container">
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className={
                  formik.errors.email && formik.touched.email
                    ? "input-error"
                    : "input-email"
                }
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error">{formik.errors.email}</div>
              ) : null}
            </div>
            <br />
            <div className="input-container">
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className={
                  formik.errors.password && formik.touched.password
                    ? "input-error"
                    : "input-email"
                }
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>
            <h6>He olvidado mi contraseña</h6>
            <IniciaSesion type="submit">
            </IniciaSesion>
          </form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const LoginImage = () => {
  return (
    <div>
    </div>
  );
};

export default class homePage extends Component {
  render() {
    return (
      <div>
        <Container className="login-container">
          <Row className="left-login-row">
            <Col className="login-left-col">
              <LoginImage />
            </Col>
            <Col className="login-right-col">
              <LoginForm />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
