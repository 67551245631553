import React, { Component } from "react";
import { Button } from "react-bootstrap";

const RegistrarseButton = () => {
  return (
    <div>
      <Button className="register-button" type="submit">
        REGISTRARSE
      </Button>
    </div>
  );
  //}
};

export default class Registrarse extends Component {
  render() {
    return (
      <div>
        <RegistrarseButton />
      </div>
    );
  }
}