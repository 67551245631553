import "bootstrap/dist/css/bootstrap.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Nav, NavLink, Button } from "reactstrap";
import { Component } from "react";
import example from "../images/example.svg";
import arrow from "../images/arrow.svg";
import EmpezarUnMeraki from "../../../components/EmpezarUnMerakiFondoNegro";

export default class ExampleContainer extends Component {
  render() {
    return (
      <div className="example-div">
        <Container className="example-container">
          <Row className="example-images">
            <Col>
              <div className="example-div">
                <h1 className="example-titulo">
                  Inspírate y comienza <br />
                  un meraki desde uno <br />
                  de nuestros estilos
                </h1>
                <br />
                <h5 className="example-comentario">
                  O empieza a crear desde cero.
                </h5>
                <br />
                <div className="empezar-meraki">
                  <EmpezarUnMeraki />
                </div>
              </div>
            </Col>
            <Col className="example-buttons">
              <ul>
                <Button className="cumple-button">
                  <Row>
                    <Col className="example-button-col">
                      <text className="example-button-text">Cumpleaños</text>
                    </Col>
                    <Col className="example-image-col">
                      <img src={arrow} className="arrow" alt="arrow" />
                    </Col>
                  </Row>
                </Button>
              </ul>
              <ul>
                <Button className="navidad-button">
                  <Row>
                    <Col className="example-button-col">
                      <text className="example-button-text">Navidad</text>
                    </Col>
                    <Col className="example-image-col">
                      <img src={arrow} className="arrow" alt="arrow" />
                    </Col>
                  </Row>
                </Button>
              </ul>
              <ul>
                <Button className="aniversario-button">
                  <Row>
                    <Col className="example-button-col">
                      <text className="example-button-text">Aniversario</text>
                    </Col>
                    <Col className="example-image-col">
                      <img src={arrow} className="arrow" alt="arrow" />
                    </Col>
                  </Row>
                </Button>
              </ul>
              <ul>
                <Button className="boda-button">
                  <Row>
                    <Col className="example-button-col">
                      <text className="example-button-text">Bodas</text>
                    </Col>
                    <Col className="example-image-col">
                      <img src={arrow} className="arrow" alt="arrow" />
                    </Col>
                  </Row>
                </Button>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
