import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EmpezarUnMeraki from "../../../components/EmpezarUnMerakiFondoNegro";
import logo from "../images/welcome-image.png";

export default class WelcomePage extends Component {
  render() {
    return (
      <div className="welcome-div">
        <Container className="welcome-container">
          <Row className="left-welcome-row">
            <Col className="welcome-left-col">
              <h1 className="titulo">
                meraki son tarjetas
                <br />
                con mensajes
                <br />
                personalizados para
                <br />
                que puedas felicitar
                <br />a tu estilo
              </h1>
              <h5 className="comentario">
                Infinitas tarjetas con fotos y vídeos creadas de forma
                <br />
                compartida junto a tus amigos y familia.
              </h5>
              <br />
              <div className="empezar-meraki">
                <EmpezarUnMeraki />
              </div>
            </Col>
            <Col>
              <img src={logo} className="welcome-image" alt="logo" />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
