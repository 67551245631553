import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Media } from 'reactstrap';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

export default class About extends Component {

   
  render() {
    return(
        <Container className="container">
          <div className="row row-content">
              <div className="col-12 col-md-6">
                  <h2>Sobre Meraki</h2>
                  <p>Siendo que Meraki significa hacer las cosas con creatividad y amor, la personalidad de la marca no puede ser menos.</p>
                  <p>Meraki es joven, agradecida y muy creativa. Nunca tiene miedo de afrontar riesgos, siempre y cuando eso no afecte a terceros. Es muy alegre, festiva y siempre habla con optimismo, pero cuando se trata de hacer negocios sabe perfectamente cómo cambiar el tono sin dejar de ser ella misma.</p>
                  <p>Es muy confiable y tiene un rol de “mamá gallina” muy metida en su ADN, siempre quiere cuidar y proteger a los suyos. </p>
                  </div>
              <div className="col-12 col-md-5">
                  <Card>
                      <CardHeader className="bg-primary text-white">Posible Tarjeta Con Cosas</CardHeader>
                      <CardBody>
                          <dl className="row p-1">
                              <dt className="col-6">INFO 1:</dt>
                              <dd className="col-6">Feliz</dd>
                              <dt className="col-6">INFO 2:</dt>
                              <dd className="col-6">Martin</dd>
                              <dt className="col-6">INFO 3:</dt>
                              <dd className="col-6">Escarlata</dd>
                          </dl>
                      </CardBody>
                  </Card>
              </div>
          </div>
        </Container>
    );
  }
}
