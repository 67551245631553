import React, { Component } from "react";
import { Button } from "react-bootstrap";

const EmpezarMerakiButton = () => {
  return (
    <div>
      <Button className="crea-meraki-borde-negro">
        EMPEZAR UN MERAKI
      </Button>
    </div>
  );
  //}
};

export default class EmpezarMerakiBordeBlanco extends Component {
  render() {
    return (
      <div>
        <EmpezarMerakiButton />
      </div>
    );
  }
}